import React, {useEffect, useState} from "react";
import Slider from "./components/Slider";
import InitialBanner from "./components/InitialBanner";
import axios from "axios";
import {useTranslation} from "react-i18next";
import Loader from "./components/Loader";




const LearningUnits = () => {
  const [isBrowseTopic, setIsBrowseTopic] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState(false);
    const [topics, setTopics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [t, i18n] = useTranslation("global");



    function browseTopicClick(id) {
        setIsBrowseTopic(true);
        setSelectedTopic(id);
    }

    useEffect(() => {
        // Fetch data from API
        axios.get(process.env.REACT_APP_BACKEND_URL + '/api/topics/false/'+i18n.language)
            .then(response => {
                setTopics(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error fetching data from API', error);
            });
    }, [i18n.language]);

    return (
    <div style={{height:"100%", width:"100%", margin:0}}>
      <div
           style={{height:"100%", width:"100%", margin:0, display:"grid", gridColumn:"1", gap:4, justifyItems:"center"}}
      >
        <div
          style={{
            textShadow: "rgba(0,0,0,0.5) 1px 0 10px",
            width:"100%",
              height:"100%",
              position:"relative",
              textAlign:"center",
              color:"white",
              fontSize:"15vh"
          }}
        >
          <div
              style={{
                fontFamily: "Poppins, sans-serif",
                textShadow: "rgba(0,0,0,0.5) 1px 0 10px",
                textAlign: "center", // Align the text content
                position:"relative",
                  width:"100%",
                  height:"100%",
              }}>
              {isLoading ? (<div style={{ width: '100%', height: "100%", position: "relative", marginTop: 0, marginBottom: 0 }}><Loader/></div>): (
                  <>
                  {isBrowseTopic && (<Slider isTeacher={false} selectedTopic={selectedTopic} topics={topics}/>)}
              {!isBrowseTopic && (<InitialBanner isTeacher={false} topics={topics} browseTopic={browseTopicClick}/>)}
                  </>
              )}
              </div>
        </div>
      </div>
    </div>
  );
};
export default LearningUnits;

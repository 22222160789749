import React, {useEffect, useState} from "react";
import "../css/stem-input.css"
import "../css/home-page-users.css"
import backButton from "../svg/backButton.png"
import FileItem from "./components/FileItem";
import DecorativeBar from "./components/DecorativeBar";
import {useTranslation} from "react-i18next";
import greenDownloadIcon from "../svg/download-icon-green.png";
import '../css/files.css';
import axios from "axios";
import H5PModal from "./components/H5PModal";
import {useLocation, useNavigate, useParams} from "react-router-dom";



const FilesView = () => {
    const { topicId, preselectedId } = useParams();
    const location = useLocation();
    const {topicFileId} = location.state || {};
    const [materials, setMaterials] = useState();
    const [t, i18n] = useTranslation("global");
    const [, setSelectedMedia] = useState("");
    const [selectedId, setSelectedId] = useState(preselectedId);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [selectedName, setSelectedName] = useState();
    const [, setSelectedImage] = useState('');
    const [, setSelectedFile] = useState();
    const [, setSelectedFileHP5] = useState();
    const [files, setFiles] = useState([]);
    const [numberOfItemsSeen, setNumberOfItemsSeen] = useState(0);
    const [unitData, setUnitData] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);
    const [openSubtopic, setOpenSubtopic] = useState(null); // Track opened subtopic
    const [isLoading, setIsLoading] = useState(true);
    const [subtopics, setSubtopics] = useState([]); // Track opened subtopic
    const [h5pMaterials, setH5pMaterials] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();



    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };



    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/api/materials-by-subtopic/'+topicId+'/'+i18n.language)
            .then(response => {
                setSubtopics(response.data.subtopics);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error fetching data from API', error);
            });
    }, [i18n.language]);

    useEffect(() => {
        // Fetch data from API
        axios.get(process.env.REACT_APP_BACKEND_URL + '/api/materials/'+topicId+'/'+i18n.language)
            .then(response => {
                const data = response.data;
                const transformedMaterials = data.reduce((acc, obj) => {
                    acc[obj.id] = obj;
                    return acc;
                }, {});
                setMaterials(transformedMaterials);
                setFiles(transformedMaterials);
                // Determine the selectedId
                const temp_selectedId =
                    transformedMaterials[preselectedId] !== undefined
                        ? preselectedId
                        : Object.keys(transformedMaterials)[0] || null;
                setSelectedId(temp_selectedId);
                if (temp_selectedId) {
                    // If a valid ID is found, set values accordingly
                    setSelectedTitle(transformedMaterials[selectedId]?.title);
                    setSelectedDescription(transformedMaterials[selectedId]?.description);
                    setSelectedName(transformedMaterials[selectedId]?.name);
                    setSelectedImage(transformedMaterials[selectedId]?.file?.path);
                    setSelectedFileHP5(transformedMaterials[selectedId]?.file?.h5p);
                } else {
                    // If no valid ID is found, set parameters to null
                    setSelectedTitle(null);
                    setSelectedDescription(null);
                    setSelectedName(null);
                    setSelectedImage(null);
                    setSelectedFileHP5(null);
                }

            })
            .catch(error => {
                console.error('Error fetching data from API', error);
            });
    }, [topicId, i18n.language]);

    useEffect(() => {
        if(subtopics[0]?.id){
            const index = subtopics.findIndex(subtopic => subtopic.id === subtopics[0]?.id);
           getH5PMaterials(index);
        }
    }, [subtopics]);
    


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // Function to handle saving data to localStorage
    const saveDataToLocalStorage = (updatedItems) => {
        localStorage.setItem(topicId, JSON.stringify(updatedItems));
    };



    useEffect(() => {
        const savedData = localStorage.getItem(topicId);
        if (savedData) {
            setUnitData(JSON.parse(savedData));
            setNumberOfItemsSeen(JSON.parse(savedData).length);
            Object.keys(JSON.parse(savedData)).forEach(function(index) {
                let files_temp = files;
                if (files_temp[JSON.parse(savedData)[index]]) {
                    files_temp[JSON.parse(savedData)[index]].seen = true;
                    setFiles(files_temp);
                }
            });

        }
    }, [topicId] );

    const addItemToSeen = (id) => {
        if (!unitData.includes(id)) {
            const updatedItems = [...unitData, id];
            setUnitData(updatedItems);
            saveDataToLocalStorage(updatedItems);
            setNumberOfItemsSeen(updatedItems.length);
        }
        let files_temp = files;
        if (files_temp[id]) {
            files_temp[id].seen = true;
            setFiles(files_temp);
        }
    };


    function select(id){
        Object.entries(files).forEach((file)=>{
            file.isSelected = false;
        });
        files[id].isSelected = true;
        setSelectedId(id);
        setSelectedTitle(files[id].title);
        setSelectedName(files[id].name);
        setSelectedMedia(files[id].media);
        setSelectedDescription(files[id].description);
        setSelectedImage(files[id].file.path ?? '');
        setSelectedFile(files[id].file);
        setSelectedFileHP5(files[id].hp5);
        if (files[id]){
            addItemToSeen(files[id].id);
        }
    }

    // Function to handle the accordion toggle
    const toggleSubtopic = (subtopicId) => {
        setOpenSubtopic((prev) => (prev === subtopicId ? null : subtopicId));
    
        const materials = subtopics[subtopicId]?.materials || [];
    
        const desiredMaterial = materials.find(
            (material) =>
                material.title === selectedTitle 
        ) || materials[0]; 
    
        if (desiredMaterial) {
            select(desiredMaterial.id); 
        }
        getH5PMaterials(subtopicId);
    };
    

    const getH5PMaterials = (subtopicId) => {
        let h5p_list = [];
        subtopics[subtopicId].materials.forEach((material) => {
            if (material.file.type === 'h5p'){
                h5p_list.push(material);
            }
        });
        setH5pMaterials(h5p_list);

    }

    const handleBackClick = () => {
        navigate('/');
    }


    const FilePreview = () => {
        let id = selectedId;
        const fileType = files[id].file.type;

        switch (fileType) {
            case 'image':
                return <ImagePreview file={process.env.REACT_APP_BACKEND_URL + files[id].file.path}/>;
            case 'pdf':
                // Render a document preview component
                return <PDFPreview file={files[id].file}  type={'pdf'}/>;
            case 'word':
                // Render a document preview component
                return <NoPreview file={files[id].file} type={'docx'}/>;
            case 'video':
                // Render a video preview component
                return <VideoPreview file={files[id].file.h5p}/>;
            case 'powerpoint':
                return <NoPreview file={files[id].file} type={'pptx'}/>;
            case 'h5p':
                return  <H5PPreview file={files[id].file.h5p}/>;
            default:
                // Render a generic preview for unknown file types
                return ;
        }
    };

    const H5PPreview = ({ file }) => {
        return (
            <>
                <div className="h5p-preview-title">
                    {selectedTitle}
                </div>
                {selectedName && (
                    <>
                        <div className="h5p-preview-container">
                            <div className="h5p-preview-box">
                                <div
                                    className="h5p-preview-content"
                                    dangerouslySetInnerHTML={{ __html: file }}
                                />
                            </div>
                        </div>

                    </>
                )}
            </>
        );
    };
    const ImagePreview = ({ file }) => {
        return (
            <>
                <div className="image-preview-title">
                    {selectedTitle}
                </div>
                <div className="image-preview-description">
                    {selectedDescription}
                </div>
                {selectedName && (
                    <>
                        <div className="image-preview-container">
                            <div className="image-preview-box">
                                <div className="image-preview-img-wrapper">
                                    <img
                                        className="image-preview-img"
                                        src={file}
                                        alt="Selected item"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="image-preview-selected-name">
                            {selectedName}
                        </div>
                    </>
                )}
            </>
        );
    };

    const PDFPreview = ({ file, type }) => {
        const [fileUrl, setFileUrl] = useState('');

        useEffect(() => {
            const fetchFileUrl = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/files/${file.id}/url`);
                    setFileUrl(response.data.url);
                } catch (error) {
                    console.error('Error fetching file URL:', error);
                    // Optional: Handle specific error scenarios
                    if (error.response) {
                        console.error('Response error:', error.response.data);
                    } else if (error.request) {
                        console.error('Request error:', error.request);
                    } else {
                        console.error('General error:', error.message);
                    }
                }
            };

            fetchFileUrl();
        }, [file]);


        return (
            <>
            {isMobile ? (
                <>
                    <div>
                        <div className="no-preview-title">{selectedTitle}</div>
                        <div className="pdf-viewer-container">
                            <iframe
                                src={fileUrl}
                                width="100%"
                                height="400px"
                                title="PDF Viewer"
                                frameBorder="0"
                            />
                        </div>
                    </div>
                </>
            ):(
                <>
                    <div>
                        <div className="no-preview-title">{selectedTitle}</div>
                        <div className="pdf-viewer-container">
                            <iframe
                                src={fileUrl}
                                width="100%"
                                height="600px"
                                title="PDF Viewer"
                                frameBorder="0"
                            />
                        </div>
                    </div>
                </>
            )}
            </>
        );
    };


    const VideoPreview = ({ file }) => {
        return (
            <>
                <div className="video-preview-title">
                    {selectedTitle}
                </div>
                {selectedName && (
                    <>
                        <div className="video-preview-container">
                            <div className="video-preview-box">
                                <div
                                    className="video-preview-embed"
                                    dangerouslySetInnerHTML={{ __html: file }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };


    const NoPreview = ({ file, type }) => {
        return (
            <>
                {isMobile ? (
                    <>
                        <div className="no-preview-title">
                            {selectedTitle}
                        </div>
                        <div className="no-preview-description">
                            {selectedDescription}
                        </div>
                        {selectedName && (
                            <div className="no-preview-mobile-container">
                                <div className="no-preview-box">
                                    <div className="no-preview-message-container">
                                        <div>
                                            <div className="no-preview-text">
                                                {t("files.no_preview")}
                                            </div>
                                            <div className="no-preview-download-button">
                                                <button className="download-button">
                                                    <a
                                                        href={process.env.REACT_APP_BACKEND_URL + "/api/download-file/" + file?.id}
                                                        download={selectedName}
                                                        onClick={() => addItemToSeen(selectedId)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{ color: "rgb(0, 231, 110,1)" }}
                                                    >
                                                        <div className="no-preview-download-button-content">
                                                            <div style={{ display: "flex", marginRight: "2vw" }}>
                                                                <p>{t("slider.download")}</p>
                                                            </div>
                                                            <div className="no-preview-download-icon">
                                                                <img alt="Download icon" src={greenDownloadIcon} />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="no-preview-file-name">
                                    {selectedName}
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div>
                        <div className="no-preview-title">
                            {selectedTitle}
                        </div>
                        <div className="no-preview-description">
                            {selectedDescription}
                        </div>
                        {selectedName && (
                            <div className="no-preview-desktop-container">
                                <div className="no-preview-box">
                                    <div className="no-preview-message-container">
                                        <div>
                                            <div className="no-preview-text">
                                                {t("files.no_preview")}
                                            </div>
                                            <div className="no-preview-download-button">
                                                <button className="download-button">
                                                    <a
                                                        href={process.env.REACT_APP_BACKEND_URL + "/api/download-file/" + file?.id}
                                                        download={selectedName}
                                                        onClick={() => addItemToSeen(selectedId)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{ color: "rgb(0, 231, 110,1)" }}
                                                    >
                                                        <div className="no-preview-download-button-content">
                                                            <div style={{ display: "flex", marginRight: "2vw" }}>
                                                                <p>{t("slider.download")}</p>
                                                            </div>
                                                            <div className="no-preview-download-icon">
                                                                <img alt="Download icon" src={greenDownloadIcon} />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    };

    return (
        <>
        <div className='files-view'
             style={{
                 backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL+'/api/show-image/'+topicFileId})`,
                 backgroundSize: 'cover',
                 backgroundPosition: 'center',
                 backgroundRepeat: 'no-repeat',
                 position:"fixed",
             }}>
            {isMobile && (
                <div className="files-container">
                    <div className="files-wrapper">
                        <div className="file-center">
                            <div className="file-preview-container">
                                {files && Object.keys(files).length > 0  && (
                                    <FilePreview />
                                )}
                                {!selectedName && (
                                    <div className="no-items-container">
                                        {t("files.no_items")}
                                    </div>
                                )}
                            </div>
                            <div className="exercises">
                                {h5pMaterials && h5pMaterials.length > 0 && (
                                    <button className="slider-button-browse-units" onClick={handleOpenModal}>
                                        <p>{t("files.exercises")} ({h5pMaterials.length})</p>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="grid-container">
                            <div className="files-grid">
                                <DecorativeBar
                                    numberOfItemsSeen={numberOfItemsSeen}
                                    files={files}
                                    files_number={Object.entries(files).length}
                                />
                                <div className="accordion">
                                    <div className="files-scrollview">
                                        {subtopics && files && (
                                            <>
                                                {Object.entries(subtopics).map(([subtopicId, { title, materials }]) => (
                                                    <div key={subtopicId} className="accordion-item">
                                                        <div className="accordion-header" onClick={() => toggleSubtopic(subtopicId)}>
                                                            <h2>{title}</h2>
                                                            <span className={`accordion-arrow ${openSubtopic === subtopicId ? 'open' : ''}`}>
                                                        {openSubtopic === subtopicId ? '▲' : '▼'}
                                                    </span>
                                                        </div>
                                                        {openSubtopic === subtopicId && (
                                                            <div className="accordion-body">
                                                                <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                                                                    {materials.map((file) => {
                                                                        if (file.file.type === 'h5p') {
                                                                            return null;  // Skip rendering the FileItem for 'h5p' type
                                                                        }
                                                                        return (
                                                                            <FileItem
                                                                                key={file.id}
                                                                                id={file.id}
                                                                                file={file}
                                                                                material={file}
                                                                                index={file.id}
                                                                                isSelected={file.id === selectedId}
                                                                                name={file.title}
                                                                                path={file.path}
                                                                                type={file.type}
                                                                                select={select}
                                                                            />
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}

                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-button-container">
                        <button className="slider-button-inner-search-exit" onClick={handleBackClick}>
                            <img
                                src={backButton}
                                alt="Back button"
                            />
                        </button>
                    </div>
                </div>
            )}
            {!isMobile && (

                <div className="files-container">
                    <div className="files-wrapper">
                        <div className="right-side desktop">
                            <div className="file-preview-container">
                                {files && Object.keys(files).length > 0  && (
                                    <FilePreview />
                                )}
                            </div>
                            <div className="exercises">
                                {h5pMaterials && h5pMaterials.length > 0 && (
                                    <button className="slider-button-browse-units" onClick={handleOpenModal}>
                                        <p>{t("files.exercises")} ({h5pMaterials.length})</p>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="grid-container desktop">
                            <div className="files-grid desktop">
                                <DecorativeBar
                                    numberOfItemsSeen={numberOfItemsSeen}
                                    files={files}
                                    files_number={Object.entries(files).length}
                                />
                                <div className="accordion">
                                    <div className="files-scrollview">
                                        {files && subtopics && Object.entries(subtopics).map(([subtopicId, { title, materials }]) => (
                                            <div key={subtopicId} className="accordion-item">
                                                <div className="accordion-header" onClick={() => toggleSubtopic(subtopicId)}>
                                                    <h2>{title}</h2>
                                                    <span className={`accordion-arrow ${openSubtopic === subtopicId ? 'open' : ''}`}>
                                                        {openSubtopic === subtopicId ? '▲' : '▼'}
                                                    </span>
                                                </div>
                                                {openSubtopic === subtopicId && (
                                                    <div className="accordion-body">
                                                        <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                                                            { files && subtopics && materials.map((file) => {
                                                                if (file.file.type === 'h5p') {
                                                                    return null;  // Skip rendering the FileItem for 'h5p' type
                                                                }
                                                                return (
                                                                    <FileItem
                                                                        key={file.id}
                                                                        id={file.id}
                                                                        file={file}
                                                                        material={file}
                                                                        index={file.id}
                                                                        isSelected={file.id === selectedId}
                                                                        name={file.title}
                                                                        path={file.path}
                                                                        type={file.type}
                                                                        select={select}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-button-container">
                        <button className="slider-button-inner-search-exit" onClick={handleBackClick}>
                            <img
                                src={backButton}
                                alt="Back button"
                            />
                        </button>
                    </div>
                </div>
            )}
            <H5PModal isOpen={isModalOpen} onClose={handleCloseModal} materials={h5pMaterials} addItemToSeen={addItemToSeen}/>
            </div>
        </>



    );
}

export default FilesView;

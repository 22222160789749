import React, {useEffect, useState} from "react";
import "../../css/stem-input.css"
import arrow from "../../svg/blueArrow.png";
import videoIcon from "../../svg/video-icon-white.png";
import powerPointIcon from "../../svg/PPT-ICON-PNG.png";
import wordIcon from "../../svg/word.png";
import pdfIcon from "../../svg/pdfs-512.webp";
import imageIcon from "../../svg/Photo-Image-Icon-Graphics-10388619-1-1-580x386.jpg";
import h5pIcon from "../../svg/h5p-logo-DE59A88B70-seeklogo.com.png";

const InnerSearchItem = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const [selectedImage, ] = useState(setIcon());

    function setId(){
        props.setId(props.material.id);
    }

    function setIcon(){
        if (props.file.type === 'word'){
            return wordIcon;
        }else if(props.file.type === 'pdf'){
            return pdfIcon;
        }else if(props.file.type === 'powerpoint'){
            return powerPointIcon;
        }else if(props.file.type === 'video'){
            return videoIcon;
        }else if(props.file.type === 'h5p'){
            return h5pIcon;
        }else if(props.file.type === 'image'){
            return imageIcon;
        }
    }


    return (
        <>
            {isMobile && (
                <>
                    <div style={{ position: "relative", display: "flex", flexDirection: "row", backgroundColor: "white", borderRadius: "25px", padding: "2%", width: "100%", height: "100%" }}>
                        <div key={props.key} style={{ position: "relative", fontFamily: "Poppins, sans-serif", width: "100%", height: "100%" }}>
                            <a onClick={setId}>
                                <div style={{ position: "relative", fontFamily: "Poppins, sans-serif", display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "80%", marginRight: "5%", marginLeft: "5%" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <b><p style={{ fontSize: "2vh", marginBottom: 0, color: "rgba(29, 62, 77,0.9)", textAlign: "left" }}>{props.material.name}</p></b>
                                            <p style={{ fontSize: "1.5vh", marginTop: 0, paddingTop: 0, color: "rgba(29, 62, 77,0.9)", textAlign: "left" }}>{props.material.title}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "10%", display: "flex", alignItems: "center" }}>
                                        <img alt="Left arrow" src={arrow} style={{ width: "5vw", height: "auto" }} />
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </>




            )
            }
            {!isMobile && (
                <>
                    <div style={{ position: "relative", display: "flex", flexDirection: "row", backgroundColor: "white", borderRadius: "50px", padding: "2%", width: "100%", height: "100%" }}>
                        <div key={props.key} style={{ position: "relative", fontFamily: "Poppins, sans-serif", width: "100%", height: "100%" }}>
                            <a onClick={setId}>
                                <div style={{ position: "relative", fontFamily: "Poppins, sans-serif", display: "flex", alignItems: "center"}}>
                                    <div style={{width:"10%", float:"left", position:"relative"}}>
                                        <img alt="Background"
                                             style={{width:"8vh",
                                                 height:"8vh",
                                                 borderRadius:"50px",
                                                 position:"relative",
                                                 padding:"5%",
                                                 backgroundColor:"rgba(29, 62, 77,0.9)",
                                                objectFit:"cover"}} src={selectedImage}/>
                                    </div>
                                    <div style={{ width: "70%", marginRight: "5%", marginLeft: "5%" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <b><p style={{ fontSize: "2vh", marginBottom: 0, color: "rgba(29, 62, 77,0.9)", textAlign: "left" }}>{props.material.name}</p></b>
                                            <p style={{ fontSize: "1.5vh", marginTop: 0, paddingTop: 0, color: "rgba(29, 62, 77,0.9)", textAlign: "left" }}>{props.material.title}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "10%", display: "flex", alignItems: "center" }}>
                                        <img alt="Right arrow" src={arrow} style={{ width: "1vw", height: "auto" }} />
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default InnerSearchItem;

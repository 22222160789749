import React, { useState, useEffect } from 'react';
import SliderItem from "./SliderItem";
import axios from 'axios';
import backArrow from "../../svg/back.png";
import nextArrow from "../../svg/next.png";
import Loader from "./Loader";
import {useTranslation} from "react-i18next";
import "../../css/home-page-users.css";


export default function Slider(props) {
    const [, i18n] = useTranslation("global");
    const [currentSlide, setCurrentSlide] = useState(Math.max(props.selectedTopic - 1, 0));
    const [isNavigationArrows, setIsNavigationArrows] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(true);
    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const numberSlides = props.topics.length;
    const [isLoading, setIsLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);
        updateArrows(Math.max(props.selectedTopic - 1, 0));

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const nextSlide = () => {
        setCurrentSlide(currentSlide + 1);
        updateArrows(currentSlide + 1);

    }

    const previousSlide = () => {
        setCurrentSlide(currentSlide - 1);
        updateArrows(currentSlide - 1);
    }

    const updateArrows = (current) => {
        if(current === 0 && numberSlides === 1){
            setIsFirstSlide(true);
            setIsLastSlide(true);
        }else if (current === 0){
            setIsFirstSlide(true);
            setIsLastSlide(false);
        }else if(current === numberSlides-1){
            setIsFirstSlide(false);
            setIsLastSlide(true);
        }else{
            setIsFirstSlide(false);
            setIsLastSlide(false);
        }

    }

    return (
        <>
            {isMobile && (
                <>
                    <div style={{ width: '100%', height: "100%", position: "relative", marginTop: 0, marginBottom: 0 }}><Loader/></div>
                        <div className="slider middle" style={{ width: '100%', height:"100%", position: "fixed", marginTop: 0, marginBottom: 0}}>
                            {isNavigationArrows && (
                                <>
                                    {!isLastSlide && (
                                        <>
                                            <div className="float-right">
                                                <button style={{ position: "absolute", right: "2%", top: "50%", transform: "translateY(-50%)", zIndex: "9999", maxWidth: "30px" }} onClick={nextSlide}>
                                                    <img src={nextArrow} alt="Next Arrow" style={{ width: "100%" }} />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {!isFirstSlide && (
                                        <>
                                            <div className="float-left">
                                                <button style={{ position: "absolute", left: "2%", top: "50%", transform: "translateY(-50%)", zIndex: "9999", maxWidth: "30px" }} onClick={previousSlide}>
                                                    <img src={backArrow} alt="Back Arrow" style={{ width: "100%" }} />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            <div className="slides">
                                {props.topics.map((slide, index) => (
                                    <SliderItem key={index} index={index} data={slide} setArrows={setIsNavigationArrows} isNavigationArrows={isNavigationArrows} currentSlide={currentSlide} />
                                ))}
                            </div>
                        </div>

                </>
            )}
            {!isMobile && (
                <>
                    <div style={{ width: '100%', height: "100%", position: "relative", marginTop: 0, marginBottom: 0 }}><Loader/></div>
                        <div className="slider middle" style={{ width: '100%', height:"100%", position: "fixed", marginTop: 0, marginBottom: 0}}>
                            {isNavigationArrows && (
                                <>
                                    {!isLastSlide && (
                                        <>
                                            <div className="float-right">
                                                <button style={{ position: "absolute", right: "2%", top: "50%", transform: "translateY(-50%)", zIndex: "9999", maxWidth: "50px" }} onClick={nextSlide}>
                                                    <img src={nextArrow} alt="Next Arrow" style={{ width: "100%" }} />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {!isFirstSlide && (
                                        <>
                                            <div className="float-left">
                                                <button style={{ position: "absolute", left: "2%", top: "50%", transform: "translateY(-50%)", zIndex: "9999", maxWidth: "50px" }} onClick={previousSlide}>
                                                    <img src={backArrow} alt="Back Arrow" style={{ width: "100%" }} />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            <div className="slides">
                                {props.topics.map((slide, index) => (
                                    <SliderItem key={index} index={index} data={slide} setArrows={setIsNavigationArrows} isNavigationArrows={isNavigationArrows} currentSlide={currentSlide} />
                                ))}
                            </div>
                        </div>
                </>
            )}
        </>

    );
}

import '../../css/ContactForm.css';
import { useTranslation } from 'react-i18next';
import {useRef, useState} from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
    const {t} = useTranslation("global");
    const form = useRef();
    const [, setEmailSent] = useState(false)
    const [, setLoading] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        emailjs
            .sendForm(
                "service_w3ayt6n",
                "template_gg4julp",
                form.current,
                "VbAKaioqfBm6WBIdy"
            )
            .then(
                (result) => {
                    setEmailSent(true);
                    setLoading(false);
                },
                (error) => {
                    setEmailSent(false);
                    setLoading(true);
                }
            );
        e.target.reset();
    };


    return (
      <section id="contact-form" className="contact-form">
          <form className="contact-form"
                onSubmit={sendEmail}
                ref={form}>
              <input id="contact-input" name="from_name" className="contact-form" type="text" placeholder={t("contacts.name_placeholder")}/>
              <input id="contact-input" name="email" className="contact-form" type="text" placeholder={t("contacts.email_placeholder")}/>
              <textarea id="contact-input" name="message" className="contact-form" placeholder={t("contacts.message_placeholder")}/>
              <div className="send-message-button">
                  <button>
                      <span className='send-message-span'><b>{t("contacts.send_message")}</b></span>
                  </button>
              </div>
          </form>



      </section>
    )
}

export default ContactForm;

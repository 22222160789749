import React, {useEffect, useState} from 'react';
import bg from "../../svg/bg.png";
import backgroundPattern from "../../svg/background-pattern.png";
import {useTranslation} from "react-i18next";
import "../../css/home-page-users.css";
import axios from "axios";



const InitialBanner = (props) => {
    const [t, i18n] = useTranslation("global");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isMobile && (
                <div className="slider middle" style={{width: '100%', height: '100%', marginTop: 0, marginBottom: 0}}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: "center",
                        position: "absolute",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        height: "100%"
                    }}>
                        <div className="slider-button-learn-stem"
                             style={{
                                 position: "relative",
                                 backgroundImage: backgroundPattern,
                                 textAlign: "center",
                                 pointerEvents: "none"
                             }}>
                            <div style={{left: 0, position: "relative", marginBottom: "-2%"}}>
                                <p style={{
                                    position: "relative",
                                    left: 0,
                                    height: "auto",
                                    padding: "0 !important",
                                    margin: "0 !important",
                                    fontSize: "7vw"
                                }}><b>{t("initial_banner.learn_stem")}</b></p>
                            </div>
                            <div style={{left: 0, verticalAlign: "baseline"}}>
                                <p style={{
                                    position: "relative",
                                    fontSize: "1.5vh",
                                    alignItems: "start",
                                    left: 0
                                }}>{t("initial_banner.learning_units_portal")}</p>
                            </div>

                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            width: '70%',
                            justifyContent: 'space-evenly',
                            verticalAlign: 'center',
                            gap:"10px"
                        }}>
                            {props.topics && props.topics.map((topic) => (

                                    <button className="slider-button-browse-units" style={{position: "relative"}}
                                            onClick={() => {
                                                props.browseTopic(topic.topic_parent.order)
                                            }}>
                                        <p>{topic.title}</p>
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                    <div className="slides">
                        <div className={`slide`} style={{display: 'block'}}>
                            <img src={bg} alt={`Slide`} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                        </div>
                    </div>
                </div>
            )}
            {!isMobile && (
                <div className="slider middle" style={{width: '100%', height: '100%', marginTop: 0, marginBottom: 0}}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: "center",
                        position: "absolute",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        height: "100%"
                    }}>
                        <div className="slider-button-learn-stem"
                             style={{
                                 position: "relative",
                                 backgroundImage: backgroundPattern,
                                 textAlign: "center",
                                 pointerEvents: "none"
                             }}>
                            <div style={{left: 0, position: "relative", marginBottom: "-2%"}}>
                                <p style={{
                                    position: "relative",
                                    left: 0,
                                    height: "auto",
                                    padding: "0 !important",
                                    margin: "0 !important",
                                    fontSize: "7vw"
                                }}><b>{t("initial_banner.learn_stem")}</b></p>
                            </div>
                            <div style={{left: 0, verticalAlign: "baseline"}}>
                                <p style={{
                                    position: "relative",
                                    fontSize: "1.5vh",
                                    alignItems: "start",
                                    left: 0
                                }}>{t("initial_banner.learning_units_portal")}</p>
                            </div>

                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                            width: '50%',
                            justifyContent: 'space-evenly',
                            verticalAlign: 'center'
                        }}>
                            {props.topics && props.topics.map((topic) => (

                                    <button className="slider-button-browse-units" style={{position: "relative"}}
                                            onClick={() => {
                                                props.browseTopic(topic.topic_parent.order)
                                            }}>
                                        <p>{topic.title}</p>
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                    <div className="slides">
                        <div className={`slide`} style={{display: 'block'}}>
                            <img src={bg} alt={`Slide`} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default InitialBanner;

import React, { useEffect, useState } from 'react';
import backArrow from "../../svg/back.png";
import nextArrow from "../../svg/next.png";
import { useTranslation } from "react-i18next";
import "../../css/H5PModal.css";
import * as ReactDom from "react-dom"; // Import the CSS file

const H5PModal = ({ isOpen, onClose, materials, addItemToSeen }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [t] = useTranslation("global");

    useEffect(() => {
        if (isOpen && materials.length > 0) {
            addItemToSeen(materials[currentIndex]?.id);
        }
    }, [currentIndex, isOpen, materials, addItemToSeen]);

    useEffect(() => {
        setCurrentIndex(0);
    }, [isOpen, materials]);

    if (!isOpen || materials.length === 0) return null;

    const nextMaterial = () => {
        setCurrentIndex((prev) => (prev + 1) % materials.length);
    };

    const prevMaterial = () => {
        setCurrentIndex((prev) => (prev - 1 + materials.length) % materials.length);
    };

    const goToMaterial = (index) => {
        setCurrentIndex(index);
    };

    return  ReactDom.createPortal(
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close" onClick={onClose}>
                    X
                </button>
                <h2>{t("files.exercises")}</h2>
                <h3>
                    {materials[currentIndex]?.title}
                </h3>
                <div dangerouslySetInnerHTML={{ __html: materials[currentIndex]?.file.h5p }} />
                <div className="arrow-nav-container">
                    {/* Left Arrow */}
                    {currentIndex > 0 && (
                        <button className="arrow-button left" onClick={prevMaterial}>
                            <img src={backArrow} alt="Back" />
                        </button>
                    )}

                    {/* Index Buttons */}
                    <div className="nav-buttons">
                        {materials.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => goToMaterial(index)}
                                className={`nav-button ${currentIndex === index ? "active" : ""}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>

                    {/* Right Arrow */}
                    {currentIndex < materials.length - 1 && (
                        <button className="arrow-button right" onClick={nextMaterial}>
                            <img src={nextArrow} alt="Next" />
                        </button>
                    )}
                </div>
            </div>
        </div>, document.body
    );
};

export default H5PModal;

import React, {useEffect, useRef, useState} from "react";
import "../../css/stem-input.css"
import SearchItem from "./SearchItem";
import Files from "./Files";
import axios from "axios";
import FilesIcon from "./FilesIcon";
import {useTranslation} from "react-i18next";
import "../../css/home-page-users.css";
import { useNavigate } from 'react-router-dom';



const SliderItem = (props) => {
    const [t, i18n] = useTranslation("global");
    const [isSearching, setIsSearching] = useState(false);
    const [isSearchBarActive, setIsSearchBarActive] = useState(true);
    const [isMainViewActive, setIsMainViewActive] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [isFilesActive, setIsFilesActive] = useState(false);
    const [materials, setMaterials] = useState([]);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [selectedId, setSelectedId] = useState(0);
    const inputRef = useRef(null); // Create a ref for the input field
    const [refreshKey, setRefreshKey] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);
    const [wasFetched, setWasFetched] = useState(false);
    const navigate = useNavigate();




    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    function searchBarChange(event) {
        setSearchText(event.target.value);
        if(event.target.value.length >0){
            selectMaterials(event.target.value);
            setIsMainViewActive(false);
            setIsSearching(true);
        }else{
            setIsSearching(false);
            setIsMainViewActive(true);
        }
    }

    function closeSearchItems(){
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    }

    function selectMaterials(text){
        let temp_list = [];
        Object.values(materials).forEach(material => {
            if (
                (text !== '' || text !== ' ') &
                material.name.toLowerCase().includes(text.toLowerCase()) ||
                material.title.toLowerCase().includes(text.toLowerCase()) ||
                material.description.toLowerCase().includes(text.toLowerCase())
            ) {
                temp_list.push(material);
            }
        });
        setSelectedMaterials(temp_list);
    }

    useEffect(() => {
        // Fetch data from API
        axios.get(process.env.REACT_APP_BACKEND_URL + '/api/materials/'+props.data.id+'/'+i18n.language)
            .then(response => {
                const data = response.data;
                const transformedMaterials = data.reduce((acc, obj) => {
                    acc[obj.id] = obj;
                    return acc;
                }, {});
                setMaterials(transformedMaterials);
                if (Object.keys(transformedMaterials).length > 0) {

                    const firstMaterialId = transformedMaterials[Object.keys(transformedMaterials)[0]].id;
                    setSelectedId(firstMaterialId);
                }

            })
            .catch(error => {
                console.error('Error fetching data from API', error);
            })
            .finally(()=>{
                setWasFetched(true);
            });
    }, [props.data.id]);


    useEffect(() => {
        // This effect will be triggered whenever materials changes
        setRefreshKey(prevKey => prevKey + 1);
    }, [materials]);

    function setId(id){
        setSelectedId(id);
        props.setArrows(false);
        setIsSearchBarActive(false);
        setIsSearching(false);
        setIsMainViewActive(false);
        filesActiveChange(id);
    }


    function filesActiveChange(id= selectedId) {
        if(isFilesActive){
            setIsFilesActive(false);
            setIsSearchBarActive(true);
            setIsMainViewActive(true);
            props.setArrows(true);
        }else{
            props.setArrows(false);
            //setIsFilesActive(true);
            setIsSearchBarActive(false);
            setIsMainViewActive(false);
            navigate(`/files/${props.data.id}/${id}`, { state: { materials, preselectedId:selectedId, topicFileId:props.data.file.id} });
        }
    }

    function openTeacherMaterials() {
        navigate(`/teacher-materials/${props.data.id}`);
    }

    return (

        <>
            {isMobile && (
                <div
                    key={props.index}
                    className={`slide s${props.index + 1}`}
                    style={{
                        display: props.index === props.currentSlide ? 'block' : 'none',
                        backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL+'/api/show-image/'+props.data.file.id})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height:"100%",
                    }}
                >
                    <div
                        style={{
                            width:"100%",
                            height:"100%",
                            backdropFilter: isSearching || isFilesActive ? "blur(10px)":'none',
                            position:"absolute"
                        }}/>

                    {/*Used to create some padding from the menu.*/}
                    <div style={{
                        height:"10%"
                    }}>

                    </div>
                    <div
                        style={{
                            position: 'relative',
                            left: '0',
                            padding: '20px',
                            maxWidth: '100%',
                            height:"100%"
                        }}
                    >
                        {isSearchBarActive && Object.keys(materials).length > 0 &&(
                            <>
                                <input
                                    ref={inputRef} // Assign the ref to the input field
                                    className="stem-input"
                                    type="text"
                                    id="username"
                                    name="username"
                                    placeholder={t("slider.search_placeholder")}
                                    onChange={searchBarChange}
                                    style={{
                                        height:"7vh",
                                        width:"80%",
                                        fontSize:"2vh"
                                    }}
                                />
                            </>
                        )}
                        {isSearching && Object.keys(materials).length > 0 &&(
                            <>
                                <div style={{width:"100%", display: "flex", justifyContent: "center", flexWrap:"wrap", alignContent:"center", flexDirection:"column"}}>
                                    <SearchItem
                                        selectedMaterials={selectedMaterials}
                                        searchText={searchText}
                                        setIsSearching={setIsSearching}
                                        isMainViewActive={isMainViewActive}
                                        setIsMainViewActive={setIsMainViewActive}
                                        isSearching={isSearching}
                                        setId={setId}
                                        closeSearchItems={closeSearchItems}
                                    />
                                </div>
                            </>
                        )}
                        {isMainViewActive &&  Object.keys(materials).length > 0 &&(
                            <div style={{display: "flex",  flexDirection:"column", gap:"50px", width:"90%", margin:"auto", marginTop:"10%"}}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        flexDirection: "column-reverse",
                                        marginTop: "5%",
                                        height:"fit-content"
                                    }}
                                >

                                    <div>
                                        <p style={{
                                            maxWidth:"100%",
                                            top: '0',
                                            bottom: '0',
                                            margin: '0',
                                            fontSize: "5vh",
                                            wordWrap:"break-word"
                                        }}>{props.data.title}</p>

                                    </div>
                                    <div style={{position: "relative", display: "flex", fontSize: "3vh", width: "100%", justifyContent:"center"}}>
                                        <FilesIcon files_number={Object.keys(materials).length}/>
                                    </div>
                                </div>

                                <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                                    <button className="slider-button-browse-units" style={{fontSize: "2.5vh"}}
                                            onClick={() => {
                                                filesActiveChange(selectedId)
                                            }}>
                                        <p>{t("slider.browse_unit")}</p>
                                    </button>
                                    <button className="slider-button-browse-units" onClick={() => {openTeacherMaterials()}}>
                                        <p>{t("teacher.teacher_materials")}</p>
                                    </button>
                                </div>

                            </div>
                        )}

                        { wasFetched && materials.length === 0 && (
                            <>
                                <p style={{ top: '0', bottom: '0', margin: '0', fontSize:"7vh" }}>{props.data.title}</p>
                                <p>{t("slider.no_materials")}</p>
                            </>
                        )}
                    </div>
                </div>
            )}
            {!isMobile && (
                <div
                    key={props.index}
                    className={`slide s${props.index + 1}`}
                    style={{
                        display: props.index === props.currentSlide ? 'block' : 'none',
                        backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL+'/api/show-image/'+props.data.file.id})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height:"100%",
                    }}
                >
                    <div
                        style={{
                            width:"100%",
                            height:"100%",
                            backdropFilter: isSearching || isFilesActive ? "blur(10px)":'none',
                            position:"absolute"
                        }}/>

                    {/*Used to create some padding from the menu.*/}
                    <div style={{
                        height:"10%"
                    }}>

                    </div>
                    <div
                        style={{
                            position: 'relative',
                            left: '0',
                            padding: '20px',
                            maxWidth: '100%',
                        }}
                    >
                        {isSearchBarActive && Object.keys(materials).length > 0 &&(
                            <>
                                <input
                                    ref={inputRef} // Assign the ref to the input field
                                    className="stem-input"
                                    type="text"
                                    id="username"
                                    name="username"
                                    placeholder={t("slider.search_placeholder")}
                                    onChange={searchBarChange}
                                    style={{
                                        height:"7vh",
                                        width:"40%",
                                        fontSize:"2vh"
                                    }}
                                />
                            </>
                        )}
                        {isSearching && Object.keys(materials).length > 0 &&(
                            <>
                                <div style={{width:"100%", display: "flex", justifyContent: "center", flexWrap:"wrap", alignContent:"center", flexDirection:"column"}}>
                                    <SearchItem
                                        selectedMaterials={selectedMaterials}
                                        searchText={searchText}
                                        setIsSearching={setIsSearching}
                                        isMainViewActive={isMainViewActive}
                                        setIsMainViewActive={setIsMainViewActive}
                                        isSearching={isSearching}
                                        setId={setId}
                                        closeSearchItems={closeSearchItems}
                                    />
                                </div>
                            </>
                        )}
                        {isMainViewActive &&  Object.keys(materials).length > 0 &&(
                            <>
                                <div
                                    style={{display:"flex", justifyContent:"center", flexDirection:"row-reverse", marginTop:"5%"}}
                                >

                                    <div style={{width:"3%", position:"relative", display:"flex", fontSize:"3vh"}}>
                                        <FilesIcon files_number={Object.keys(materials).length}/>
                                    </div>

                                    <p style={{ top: '0', bottom: '0', margin: '0' }}>{props.data.title}</p>
                                </div>

                                <div style={{marginTop:"0", display:"flex", justifyContent:"center", gap:"20px"}}>
                                    <button className="slider-button-browse-units" onClick={() => {filesActiveChange(selectedId)}}>
                                        <p>{t("slider.browse_unit")}</p>
                                    </button>
                                    <button className="slider-button-browse-units" onClick={() => {openTeacherMaterials()}}>
                                        <p>{t("teacher.teacher_materials")}</p>
                                    </button>
                                </div>

                            </>
                        )}

                        { wasFetched && materials.length === 0 && (
                            <>
                                <p style={{ top: '0', bottom: '0', margin: '0' }}>{props.data.title}</p>
                                <p>{t("slider.no_materials")}</p>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>


    );
}

export default SliderItem;
